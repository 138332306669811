<template>
  <div class="body-container" v-if="!this.isLoading">
    <div class="d-flex flex-column mt-2">
      <div class="d-flex flex-row justify-content-between">
        <div class="mt-3 header-text"><strong>My Results:</strong></div>
        <div class="d-flex flex-row align-items-center justify-content-center col-4">
          <button @click="sportResultsSelected(1, 'soccer')" :class="sportId === 1 ? 'sport-button-selected col-6' : 'sport-button-default col-6'">Soccer</button>
          <button @click="sportResultsSelected(250, 'rugby')" :class="sportId === 250 ? 'sport-button-selected col-6' : 'sport-button-default col-6'">Rugby</button>
        </div>
      </div>
      <div v-if="noHistoricRounds" class="mt-5 text-center">No historic round results</div>
      <router-view>
        <div class="reverse-order">
          <div class="d-flex flex-column mt-2 pt-2" v-for="(leagueResult) in userResults" :key="leagueResult.id">
            <div class="d-flex flex-row align-items-center round-header">
              <div class="d-flex flex-row align-items-center col-6">
                <img class="league-icon-img" :src="this.findLeagueLogoImageModule(leagueResult[0].LeagueName)">
                <div class="round-number-text">{{ leagueResult[0].LeagueName.toUpperCase() }}</div>
              </div>
              <div class="d-flex flex-row justify-content-end round-date col-6">
                {{ moment(leagueResult[0].StartDate).format('Do MMMM YYYY') }}
              </div>
            </div>
            <div class="pt-2 table-responsive">
              <table class="table-container table-borderless col-12">
                <thead>
                <tr class="table-headers text-center">
                  <th scope="col">Teams</th>
                  <th scope="col">Game Result</th>
                  <th scope="col">Your Prediction</th>
                  <th class="your-points-header" scope="col">Your Points</th>
                </tr>
                </thead>
                <tbody class="table-body text-center" v-for="match in leagueResult" :key="match.id">
                <tr class="table-row">
                  <td class="d-flex flex-row justify-content-center">
                    <div class="d-flex flex-column align-items-center text-center">
                      <img class="badge-icon-img" :src="this.findTeamImageModule(match.Team1Name)[0]">
                    </div>
                    <div class="d-flex flex-column align-items-center text-center">
                      <img class="badge-icon-img" :src="this.findTeamImageModule(match.Team2Name)[0]">
                    </div>
                  </td>
                  <td>{{ match.Team1Result }}-{{ match.Team2Result }}</td>
                  <td v-if="sportId === 1">{{ match.Team1Score }}-{{ match.Team2Score }}</td>
                  <td v-if="sportId === 250">
                    <div v-if="match.WinMargin != null">
                      {{ match.WinMargin }} ({{ shortenRugbyTeamNames(match.UsersPickWinningTeamName) }})
                    </div>
                    <div v-if="match.WinMargin === null">
                      Draw
                    </div>
                  </td>
                  <td class="your-points-header">{{ match.Points }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </router-view>
    </div>
  </div>
</template>

<script>
import GameDataService from "@/services/gameDataService";
import {mapState, mapMutations} from 'vuex';
import moment from "moment";

export default {
  name: "MyResults",
  created: function () {
    this.moment = moment;
  },
  data() {
    return {
      noHistoricRounds: undefined,
      sportId: 1,
      selectedSport: 'soccer'
    }
  },
  computed: {
    ...mapState(['isLoading', 'user'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    async sportResultsSelected(sportId, sportType) {
      this.setIsLoading(true);
      this.sportId = sportId;
      this.selectedSport = sportType;
      this.userPredictions = (await GameDataService.getPlayerPublishedRounds(`${this.user.msisdn}`, this.sportId)).data.recordset;
      this.sortUserRounds();
      this.$router.push({name: 'MyResults', params: {sportId: this.sportId}});
      this.setIsLoading(false);
    },
    findLeagueLogoImageModule(leagueName) {
      let teamNameReformat = leagueName.split(" ").join("-").toLowerCase();
      try {
        return [require(`../assets/leagueLogos/${teamNameReformat}-logo-default.png`)];
      } catch (e) {
        return [require(`../assets/teamBadges/default-badge.png`)];
      }
    },
    shortenRugbyTeamNames(teamName) {
      teamName = teamName.replace(' Rugby', "");
      return teamName;
    },
    findTeamImageModule(teamName) {
      let teamNameReformat = teamName.split(" ").join("-").toLowerCase();
      try {
        return [require(`../assets/teamBadges/${this.selectedSport}/${teamNameReformat}-logo-icon.png`)];
      } catch (e) {
        return [require(`../assets/teamBadges/default-badge.png`)];
      }
    },
    async getUserRoundHistory() {
      this.userPredictions = (await GameDataService.getPlayerPublishedRounds(`${this.user.msisdn}`, this.sportId)).data.recordset;
    },
    sortUserRounds() {
      this.userResults = this.userPredictions.reduce((roundsResults, item) => {
        const round = (roundsResults[item.RoundID] || []);
        round.push(item);
        roundsResults[item.RoundID] = round;
        return roundsResults;
      }, {});
      this.checkForNoResults();
    },
    checkForNoResults() {
      this.noHistoricRounds = Object.keys(this.userResults).length === 0;
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.getUserRoundHistory();
    this.sortUserRounds();
    this.setIsLoading(false);
  },
}
</script>

<style scoped>
.body-container {
  background-color: #e8e8e8;
  overflow-y: hidden;
  padding-bottom: 150px;
}

.sport-button-selected, .sport-button-default {
  height: 22px;
  border: 0;
  border-radius: 2px;
  font-size: 12px;
  margin-left: 2px;
  margin-right: 2px;
  font-weight: 600;
}

.sport-button-selected {
  background-color: #EF151C;
  color: #FFFFFF;
}

.sport-button-default {
  background-color: transparent;
  color: #000000;
  border: 0.1px solid black;
}

.header-text {
  margin-left: 20px;
}

.round-header {
  border-radius: 8px;
  font-weight: 600;
  height: 34px;
  font-size: 12px;
  background-color: #2D343B;
  color: #FFFFFF;
}

.league-icon-img {
  margin-left: 8px;
  height: 20px;
}

.round-number-text {
  padding-left: 10px;
  font-weight: 600;
  font-size: 16px;
}

.table-headers {
  font-size: 10px;
  font-weight: 600;
}

.your-points-header {
  color: #EF151C;
}

.table-body {
  font-size: 10px;
  font-weight: 500;
}

.table-row {
  border-bottom: 0.2px solid #a8a8a8;
}

td {
  text-align: center;
  vertical-align: middle;
}

td {
  text-align: center;
  vertical-align: middle;
}

.round-date {
  padding-right: 10px;
}

.reverse-order {
  display: flex;
  flex-direction: column-reverse;
}

.badge-icon-img {
  width: 24px;
  margin: 2px;
}
</style>
